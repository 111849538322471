<template>
    <div class="tool">
        <div class="searchVal">
            <span>域名查询</span>
            <el-input
                size="small"
                style="width: 150px"
                placeholder="请输入标题"
                v-model="domain"
                clearable
            >
            </el-input>
            <el-button
                @click="getData()"
                type="primary"
                size="small"
                icon="el-icon-search"
            ></el-button>
        </div>
        <div class="content">
            <div class="domain_info" v-if="data.domainMX">
                <el-form label-width="100px" label-position="left" size="mini">
                    <el-form-item label="原邮箱品牌">
                        <el-tooltip
                            class="item"
                            effect="dark"
                            v-if="
                                $tableDataHandle.dominBrand(data.domainMX) ==
                                '其他邮箱'
                            "
                            :content="data.domainMX"
                            placement="top"
                        >
                            <div class="info">
                                {{ $tableDataHandle.dominBrand(data.domainMX) }}
                            </div>
                        </el-tooltip>
                        <div v-else class="info">
                            {{ $tableDataHandle.dominBrand(data.domainMX) }}
                        </div>
                    </el-form-item>
                    <el-form-item label="备案人">
                        <div class="info">
                            {{ data.icpOwner || '--' }}
                        </div>
                    </el-form-item>
                    <el-form-item label="网站名">
                        <div class="info">
                            {{ data.icpRecordName || '--' }}
                        </div>
                    </el-form-item>
                    <el-form-item label="whois">
                        <div class="info">
                            {{ data.icpDomainRecordTime || '--' }}
                            <i class="el-icon-right"></i>
                            {{
                                data.whoisDueDate
                                    ? data.whoisDueDate.split(' ')[0]
                                    : '--'
                            }}
                        </div>
                    </el-form-item>
                    <el-form-item label="域名注册平台">
                        <div class="info">
                            {{ data.whoisRegistrant }}
                        </div>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
import { domainData } from '@/api/cs/tool.js';
export default {
    data() {
        return {
            domain: '',
            data: {},
        };
    },
    mounted() {
        window.addEventListener('keydown', this.handleKeyDown, true);
    },
    methods: {
        getData() {
            let data = {
                param: {
                    domain: this.domain,
                },
            };
            if (this.domain != '') {
                domainData(data).then((res) => {
                    // console.log(res);
                    this.data = res.data;
                });
            }
        },
        // 回车键事件
        handleKeyDown(e) {
            let key = null;
            if (window.event === undefined) {
                key = e.keyCode;
            } else {
                key = window.event.keyCode;
            }
            if (key === 13) {
                //触发的事件
                this.getData();
            }
        },
    },
    destroyed() {
        window.removeEventListener('keydown', this.handleKeyDown, true);
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.tool {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 10px;
    .searchVal {
        padding: 10px 20px;
        span {
            font-size: 14px;
            color: #333;
            font-weight: 600;
            margin-right: 10px;
        }
        button {
            background: #2370eb;
            border-radius: 2px;
            border-color: #2370eb;
            margin-right: 16px;
        }
    }
}
.domain_info {
    border-radius: 8px;
    padding: 20px;

    .info {
        background: #fff;
        border-radius: 2px;
    }
}
</style>
